<template>
        <b-container fluid style="width: auto; margin-top: 0px; background-color: white; margin: 0 0 1px 0; padding: 0px;">
                <b-row align-h="start" align-v="end" style="" class="row no-gutters">
                    <b-col cols="6" md="auto" style="padding: 0px; ">  
                        <futures-auto-complete style="margin: 0px 2px 0.5px 0px" v-bind:namespace="namespace"></futures-auto-complete>
                    </b-col>
                    <b-col v-if="program === 'LongTermCharts' && seasonalSelectionMode === 'standard'" 
                style="border: 1px solid gray; padding: 1px 3px 1px 1px; margin: -2px -8px 0px 3px; white-space: nowrap;
                        background-color: black; border-radius: 4px;">
        <b-button-group> 
                <b-form-group label="" v-slot="{ ariaDescribedby }" style="margin: 2px; font-weight: bold; border-radius: 3px; padding: 0px;
                              text-align: left; background-color: white; vertical-align: bottom;">
                    <b-form-radio-group
                    v-model="seasonalSelectionMode"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    size="sm"
                    style="margin: 4px 0 0px 2px;"></b-form-radio-group>
                </b-form-group>
                    <span style="margin: 5px 0 0 11px; font-size: 1rem; color: white"> years:</span>
                    <y1 style="margin: 0px;" v-bind:namespace="namespace" ></y1>
        </b-button-group>
      </b-col>
      <b-col v-if="['SpreadCoverage', 'Calculator'].includes(program)"
                style="margin: 0px 0 6px 4px; font-size: .95rem; font-weight: bold;"> years:</b-col>
      <b-col v-if="['SpreadCoverage', 'Calculator'].includes(program)" style="margin: 0px 0 3px 0">
                <y1 v-bind:namespace="namespace" style="margin: 0px 0 0px 0;"></y1>
            </b-col>
              
        
       
                    </b-row>
                    </b-container>
</template>

<script>

    import y1 from '@/components/y1.vue';
    import futuresAutoComplete from '@/components/futures-auto-complete.vue';

    export default {
        components: {
            futuresAutoComplete, y1
        },
        mounted() {
            // console.log("commodity-box.vue mounted() starting.");
            // console.log("this.namespace=", this.namespace);
        },
        props: {
            namespace: {
                type: String,
                default: ""
            }
        },
        data: function () {
            return {
                options: [
                    {text: 'custom', value: 'custom'},
                    {text: 'standard', value: 'standard'}
                ]
            };
        },
        computed: {
            user() {
                return this.$store.state.user;
            },
            program() {
                return this.$store.state[this.namespace].program;
            },
            aligned() {
                return this.$store.state[this.namespace].aligned;
            },
            tickerInputType: {
                get() {
                    return this.user.tickerInputType;
                },
                set() {}
            },
            seasonalSelectionMode: {
                get() {
                    return this.$store.state[this.namespace].seasonalSelectionMode;
                },
                set(seasonalSelectionMode) {
                    console.log("selected seasonalSelectionMode() starting. seasonalSelectionMode=", seasonalSelectionMode + " this.namespace=", this.namespace);
                    this.$store.commit(this.namespace + '/setSeasonalSelectionMode', seasonalSelectionMode);
                }
            }
        }
    }
</script>

