<template>
    <div style="">
        <table border="0">
            <tr>
                <!--     <td style="padding: 0 4px 9px 4px; vertical-align: bottom; font-size: 12px;">
                 <b-form-checkbox
                     id="checkbox-1"
                     name="checkbox-1"
                     value="accepted"
                     unchecked-value="not_accepted"
                     >
                     automatically add unit moves
                 </b-form-checkbox> 
                   </td>  
    
            <td>   <div>    state.tickerInput:{{ tickerInput }} tickerLegs:{{ tickerLegs }} </div>
           
                   decomposedLegsArray:   <div v-for="(dummy, index) in decomposedLegsArray" v-bind:key="index">
                       <div>  {{ decomposedLegsArray[index] }} </div> 
                   </div> 
           
                   <div>focusIndex:{{ focusIndex }}  cursorIndex:{{ cursorIndex }} unitMove: {{unitMove}} symbols:{{ symbols }} </div>   
            </td>  -->

                <td style="padding: 1px; margin: 0px 0 2px 0">
                    <table border="0">
                        <!-- <tr>
                             <td colspan="100%" style="padding: 0 0 0 7px; font-size: 11px; color: rgb(201, 2, 2);">
                                 symbols: {{ symbols }}
                             </td>
                         </tr> -->
                        <tr style="margin: 0px 0px -1px 0px; border: 0px solid gray; background-color: white;">
                            <td v-for="(number, index) in numberOfLegs" v-bind:key="index" style="">

                                <table>
                                    <tr>
                                        <td style="vertical-align: bottom;">

                                    <futures-auto-complete-dropdown 
                                        style="margin: -2px 0 -1px 3px; border: 0px solid gray;"
                                        v-bind:namespace="namespace" 
                                        v-bind:index="index"
                                        v-bind:tickerInputLeg="tickerLegs[index]"
                                        v-bind:contract="contractArray[index]"
                                        v-bind:cursorIndex="cursorIndex"
                                        v-bind:focusIndex="focusIndex"
                                        :ref="'tickerLeg'+index"
                                        @legChange="updateTickerInput"
                                        @focusIndex="setFocusIndex"></futures-auto-complete-dropdown> 

                            </td>
                            <td v-if="unitMove.length > 0" style="padding: 1.5px 4px 0 1px;">
                                <span style="cursor: none; background-color: white; font-size: 12px; margin: 0 -1.5px 0 1px">* </span>
                                <span style="font-size: 12px;">{{ unitMove[index] }}</span> 
                            </td>
                        </tr>
                    </table> 
                </td>
                <td style="text-align: bottom;">       
            <b-button-group style="margin: 0px 3px 0px 7px;">
                <b-button v-on:click="getChart" ref="submit" size="sm" style="font-weight: bold; border: 1px solid gray; width: auto; height: 22px;
                          padding: 0 4px 0px 4px; background-color: blue; color: white; whitespace: nowrap"><span>get</span>&nbsp;<span>chart</span></b-button>
            </b-button-group>
            </td>
            </tr>
        </table>
        </td>
        </tr>
        </table>

        <b-modal ref="futures-auto-complete-modal" hide-footer hide-header title="Using Component Methods">
            <div class="d-block text-center">
                <h4>Not a valid ticker.</h4>
            </div>
        </b-modal>

        <b-modal ref="trade-map-output-modal" hide-footer hide-header title="">
            <div class="d-block text-center">
                <h4>Maps can only be made for the nearest open position.</h4>
                <b-button variant="success" v-on:click="changeToNearestOpen" style="margin: 20px 0 0 0; font-size: 1.2rem">Change input to {{nearestOpenTicker}}.</b-button>

            </div>
        </b-modal>
    </div>
</template>

<style scoped>
    .vtl-node-main .vtl-operation {
        display: none;
    }
</style>

<script>

    import futuresAutoCompleteDropdown from '@/components/futures-auto-complete-dropdown-revA.vue';
    import {tickerToGeneralForm, getUnitMove, tickerLegDecomposer, commodityUnits, contractNameDecomposer,
            tickerConverter, isCommodity, isContract, nearestOpenSpread, spreadTitle, createContractLists, ContractWeightedN_tuplets} from "@/js/main";
    import _ from 'lodash';
    // import * as fb from '@/firebase';

    export default {
        components: {
            futuresAutoCompleteDropdown
        },
        mounted() {
            // console.log("futures-auto-complete.vue mounted.");
            window.addEventListener('keydown', this.handleKeyDownEvent);

            let ticker = this.$store.getters[this.namespace + "/displayTicker"].replace(/\s/g, '');
            this.$store.commit(this.namespace + "/setTickerInput", ticker);
        },
        destroyed() {
            console.log("futures-auto-complete.vue destroyed.");
            window.removeEventListener('keydown', this.handleKeyDownEvent);
        },
        props: ['namespace'],
        data: function () {
            return {
                componentKey: 0,
                focusIndex: 0,
                cursorIndex: 0,
                nearestOpenTicker: "",
                initialState: {}
            };
        },
        computed: {
            email() {
                return this.$store.state.user.email;
            },
            commodityArray() {
                // console.log("commodityArray() starting.");
                let that = this;
                let comArr = this.tickerLegs.map((leg, i) => {
                    let decomposedLeg = leg === "" ? "" : tickerLegDecomposer(leg.toUpperCase());
                    console.log("i=", i, " decomposedLeg=", decomposedLeg);

                    if (decomposedLeg === "" || typeof decomposedLeg === 'undefined')
                        return;

                    let contractParts = contractNameDecomposer(decomposedLeg.contract, this.symbols);
                    // console.log("contractParts=", contractParts);

                    // let isContractObject = isContract(decomposedLeg.contract, this.symbols);
                    // console.log("isContractObject=", isContractObject);

                    return contractParts.commoditySymbol;
                }).filter(x => typeof x !== 'undefined' && x !== '' && isCommodity(x, that.symbols).isCommodity);
                return comArr;
            },
            contractArray() {
                let arr = this.tickerLegs.map((leg/*, index*/) => {
                    let decomposedLeg = (leg === "" || typeof leg === 'undefined') ? "" : tickerLegDecomposer(leg.toUpperCase());
                    // console.log("decomposedLeg=", decomposedLeg, " index=", index);
                    return typeof decomposedLeg !== 'undefined' ? decomposedLeg.contract : "";
                }); //.filter(x => typeof x !== 'undefined');

                // console.log("arr=", arr);
                return arr;
            },
            decomposedLegsArray() {
                let arr = this.tickerLegs.map((leg) => {
                    let decomposedLeg = leg === "" ? "" : tickerLegDecomposer(leg.toUpperCase());
                    // console.log("decomposedLeg=", decomposedLeg);
                    return decomposedLeg;
                }); //.filter(x => typeof x !== 'undefined');

               // console.log("arr=", arr);
                return arr;
            },
            tickerInput() {
                return this.$store.state[this.namespace].tickerInput;
            },
            tickerLegs() {
                let tickerLegs = this.tickerInput/*.replace(/\s/g, '')*/.split(/(?=[-+\s])/);
                //console.log("tickerLegs=", tickerLegs);

                /*  let formattedTickerLegs = tickerLegs.map(leg => {
                 let formattedLeg = leg; //.replace("+", "+  ").replace("-", "-  ");
                 //console.log("formattedLeg=", formattedLeg);
                 return formattedLeg;
                 });
                 console.log("formattedTickerLegs=", formattedTickerLegs); */

                return tickerLegs;
            },
            tickerLegsLength() {
                return this.tickerLegs.length;
            },
            numberOfLegs() {
                // console.log("this.tickerLegs.length=", this.tickerLegs.length);
                //return Math.max(1, this.tickerLegs.length);
                return this.tickerLegs.length;
            },
            symbols() {
                return this.$store.state.user.symbols;
            },
            unitMove() {
                // console.log("unitMove() starting. this.commodityArray=", this.commodityArray);
                let unitMoveArray = this.commodityArray.map(commodity => {
                    let isCommodityObject = isCommodity(commodity, this.symbols);
                    //console.log("isCommodityObject=", isCommodityObject);

                    let oldCommodity = isCommodityObject.commodityObject.symbol;
                    // console.log("oldCommodity=", oldCommodity);

                    return isCommodityObject.isCommodity ? getUnitMove(oldCommodity) : null;
                });

                // console.log("unitMoveArray=", unitMoveArray);
                let allSameUnitMoves = unitMoveArray.filter(x => x !== null).every(x => x === unitMoveArray[0] || x === null);
                // console.log("allSameUnitMoves=", allSameUnitMoves);

                let units = this.commodityArray.map(commodity => {
                    let isCommodityObject = isCommodity(commodity, this.symbols);
                    // console.log("isCommodityObject=", isCommodityObject);

                    let oldCommodity = isCommodityObject.commodityObject.symbol;
                    // console.log("oldCommodity=", oldCommodity);

                    return isCommodityObject.isCommodity ? commodityUnits(oldCommodity) : null;
                });

                // console.log("units=", units);
                let allSameUnits = units.every(x => x === units[0]);
                // console.log("allSameUnits=", allSameUnits);

                return allSameUnitMoves && allSameUnits ? [] : unitMoveArray;
            }
        },
        watch: {
            symbols(newSymbols, oldSymbols) {
                console.log("watching newSymbols=", newSymbols, " oldSymbols=", oldSymbols, " this.tickerInput=", this.tickerInput);
                let ticker = tickerConverter(this.tickerInput, oldSymbols, newSymbols);
                console.log(" ticker=", ticker);
                this.$store.commit(this.namespace + "/setTickerInput", ticker);
            },
            tickerLegsLength(newTickerLegsLength, oldTickerLegsLength) {
                console.log("watching newTickerLegsLength=", newTickerLegsLength, " oldTickerLegsLength=", oldTickerLegsLength, " this.focusIndex=",
                        this.focusIndex);
                let that = this;
                if (newTickerLegsLength > oldTickerLegsLength) {
                    //  console.log("increasing tickerLegsLength.");
                    // this.focusIndex++;
                    this.cursorIndex = 1;

                    this.$nextTick(function () {
                        // console.log("that.$refs=", that.$refs);
                        // console.log("that.$refs['tickerLeg" + that.focusIndex + "'][0]=", that.$refs['tickerLeg' + that.focusIndex][0]);
                        // console.log("TTTTTTTTTTTTTT this.focusIndex=", this.focusIndex);
                        //  console.log("that.$refs['tickerLeg' + (that.focusIndex-1)][0]=", that.$refs['tickerLeg' + (that.focusIndex-1)][0]);
                        that.$refs['tickerLeg' + that.focusIndex][0].$refs.input.focus();
                        that.$refs['tickerLeg' + that.focusIndex][0].$refs.input.setSelectionRange(that.cursorIndex, that.cursorIndex);
                        if (that.focusIndex > 0) {
                            that.$refs['tickerLeg' + (that.focusIndex - 1)][0].forceRerender();
                        }
                    });
                } else {
                    // console.log("decreasing tickerLegsLength.");
                    if (this.focusIndex >= newTickerLegsLength) {
                        console.log("decreasing this.focusIndex");
                        this.focusIndex--;
                        this.cursorIndex = this.tickerLegs[this.focusIndex].length;
                    } else {
                        this.cursorIndex = 0;
                    }

                    console.log("this.focusIndex=", this.focusIndex);
                    this.$nextTick(function () {
                        // console.log("that.$refs=", that.$refs);
                        // console.log("that.$refs['tickerLeg" + that.focusIndex + "'][0]=", that.$refs['tickerLeg' + that.focusIndex][0]);
                        // console.log("TTTTTTTTTTTTTT this.focusIndex=", this.focusIndex);
                        //  console.log("that.$refs['tickerLeg' + (that.focusIndex-1)][0]=", that.$refs['tickerLeg' + (that.focusIndex-1)][0]);
                        that.$refs['tickerLeg' + that.focusIndex][0].$refs.input.focus();
                        that.$refs['tickerLeg' + that.focusIndex][0].$refs.input.setSelectionRange(that.cursorIndex, that.cursorIndex);
                        that.$refs['tickerLeg' + that.focusIndex][0].showDropdown();
                        //  that.$refs['tickerLeg' + that.focusIndex][0].show = true;
                    });
                }
            }
        },
        methods: {
            changeToNearestOpen() {
                console.log("changeToNearestOpen() starting. this.nearestOpenTicker=", this.nearestOpenTicker);
                this.$store.commit(this.namespace + '/setTickerInput', this.nearestOpenTicker.replace(/\s/g, ''));
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                console.log("generalForm=", generalForm);

                this.$refs['trade-map-output-modal'].hide();
            },
            setCursorIndex(cursorIndex) {
                console.log("setCursorIndex() starting. cursorIndex=", cursorIndex);
                this.cursorIndex = cursorIndex;
            },
            setFocusIndex(index) {
                // console.log("setFocusIndex() starting. index=", index);
                this.focusIndex = index;
                // console.log("this.$refs['tickerLeg' + this.focusIndex][0]=", this.$refs['tickerLeg' + this.focusIndex][0]);
            },
            handleKeyDownEvent(event) {
                // console.log("handleKeyDownEvent() starting.");
                let that = this;
                setTimeout(() => {
                    let target = event.target.id;
                    // console.log("target=", target);
                    // console.log("event.target.value=", event.target.value);

                    // console.log(event.key, " pressed.");

                    if (event.key === "Enter" && target !== "") {
                        this.getChart();
                    }
                    if (event.key === "ArrowLeft" && target !== "") {
                        // console.log("arrow key pressed");
                        // console.log(event.key, " pressed.");
                        // console.log("selectionStart=", event.target.selectionStart, " selectionEnd=", event.target.selectionEnd);
                        that.cursorIndex = event.target.selectionStart; //that.cursorIndex + (event.key === "ArrowRight" ? 1 : -1);

                        // console.log("that.focusIndex=", that.focusIndex);

                        if (that.cursorIndex === 0 && this.focusIndex > 0) {
                            // console.log("BBBBBBBB");
                            this.focusIndex--;
                            // console.log("event.target.value=", that.tickerLegs[that.focusIndex]);
                            //  console.log("valueLength=", that.tickerLegs[that.focusIndex].length);
                            that.cursorIndex = that.tickerLegs[that.focusIndex].length;
                            that.$nextTick(function () {
                                // console.log("that.$refs=", that.$refs);
                                // console.log("that.$refs['tickerLeg" + that.focusIndex + "'][0]=", that.$refs['tickerLeg' + that.focusIndex][0]);
                                // console.log("that.$refs['tickerLeg" + that.focusIndex + "'][0].$refs=", that.$refs['tickerLeg' + that.focusIndex][0].$refs);

                                //  console.log("GGGGGGGGGGGGG");
                                // console.log("that.$refs['tickerLeg" + that.focusIndex + "'][0]=", that.$refs['tickerLeg' + that.focusIndex][0]);
                                that.$refs['tickerLeg' + that.focusIndex][0].$refs.input.focus();
                                that.$refs['tickerLeg' + that.focusIndex][0].$refs.input.setSelectionRange(that.cursorIndex, that.cursorIndex);
                                //  that.$refs['tickerLeg' + that.focusIndex][0].show = true;

                            });
                        }
                    }
                    if (event.key === "ArrowRight" && target !== "") {
                        // console.log("arrow key pressed");
                        // console.log("target=", target);
                        // console.log(event.key, " pressed.");
                        // console.log("selectionStart=", event.target.selectionStart, " selectionEnd=", event.target.selectionEnd);
                        that.cursorIndex = event.target.selectionStart; //that.cursorIndex + (event.key === "ArrowRight" ? 1 : -1);

                        let valueLength = event.target.value.length;
                        //  console.log("valueLength=", valueLength + " that.focusIndex=", that.focusIndex);

                        if (that.cursorIndex === valueLength && that.focusIndex < that.tickerLegsLength - 1) {
                            console.log("AAAAAAAA");
                            that.focusIndex++;
                            that.cursorIndex = 1;
                            that.$nextTick(function () {
                                // console.log("that.$refs=", that.$refs);
                                // console.log("that.$refs['tickerLeg" + that.focusIndex + "'][0].$refs=", that.$refs['tickerLeg' + that.focusIndex][0].$refs);

                                console.log("EEEEEEEEEEEEEEEE");
                                //  console.log("that.$refs['tickerLeg'" + that.focusIndex + "][0]=", that.$refs['tickerLeg' + that.focusIndex][0]);
                                that.$refs['tickerLeg' + that.focusIndex][0].$refs.input.focus();
                                that.$refs['tickerLeg' + that.focusIndex][0].$refs.input.setSelectionRange(that.cursorIndex, that.cursorIndex);
                                // that.$refs['tickerLeg' + that.focusIndex][0].show = true;
                            });
                        }
                    }
                    that.$refs['tickerLeg' + that.focusIndex][0].showDropdown();
                }, 100);
            },
            forceRerender() {
                console.log("forceRerender() starting. this.componentKey=", this.componentKey = " this.index=", this.index);
                this.componentKey += 1;
            },
            updateTickerInput(obj) {
                // console.log("updateTickerInput() starting. obj=", obj);

                let currentLegInput = this.tickerLegs[obj.index];
                // console.log("currentLegInput=", currentLegInput, " obj.value=", obj.value);

                let newTickerLegs = [...this.tickerLegs];
                let diff = currentLegInput.replace(obj.value, "");
                // console.log("diff=", diff);
                if (currentLegInput.length > obj.value.length && ['+', '-'].includes(diff) && obj.value.replace(" ", "").length > 0) {
                    // console.log("+ or - deleted.")
                    newTickerLegs[obj.index] = " " + obj.value;
                } else {
                    if (obj.value.includes('+') || obj.value.includes('-') || obj.index === 0 || obj.value.replace(" ", "").length === 0) {
                        // console.log("removing spaces.");
                        obj.value = obj.value.replace(" ", "");
                    }
                    newTickerLegs[obj.index] = obj.value;
                    //  console.log("newTickerLegs=", newTickerLegs);
                }

                let newTickerInput;
                //    if (obj.value.includes("+") || obj.value.includes("-") || obj.index === 0 || obj.value.replace(/\s/g, '') === "") {
                newTickerInput = newTickerLegs.join('');
                // console.log("newTickerInput=", newTickerInput);
                this.$store.commit(this.namespace + "/setTickerInput", newTickerInput);
                //  this.forceRerender();
                //   }

                this.focusIndex = obj.index;
                // console.log("obj.value.slice(-1)=", obj.value.slice(-1));
                if (["+", "-"].includes(obj.value.slice(-1)) && obj.value.length > 1) {
                    this.focusIndex++;
                }
                this.cursorIndex = obj.cursorIndex;   // obj.value.length + (obj.index > 0 ? 2 : 0); //...for the spaces after + and -.
                let that = this;
                this.$nextTick(function () {
                    // console.log("that.$refs=", that.$refs);
                    // console.log("that.$refs['tickerLeg" + obj.index + "'][0]=", that.$refs['tickerLeg' + obj.index][0]);

                    if (typeof that.$refs['tickerLeg' + obj.index][0] !== 'undefined') {
                        //console.log("else WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW");
                        //  console.log("that.$refs['tickerLeg" + obj.index + "'][0].$refs=", that.$refs['tickerLeg' + obj.index][0].$refs);
                        that.$refs['tickerLeg' + obj.index][0].$refs.input.focus();
                        that.$refs['tickerLeg' + obj.index][0].$refs.input.setSelectionRange(that.cursorIndex, that.cursorIndex);
                    } else {
                        // console.log("else XXXXXXXXXXXXXXXXXXXXXXXXXXX");
                    }
                });
            },
            getChart() {
                console.log("getChart() starting.");
                this.$refs.submit.focus();

                let isTicker = this.decomposedLegsArray.every(decomposedLeg => {
                    console.log("decomposedLeg=", decomposedLeg);
                    let isContractObject = isContract(decomposedLeg.contract, this.symbols);
                    console.log("isContractObject=", isContractObject);
                    return isContractObject.isContract;
                });
                console.log("isTicker=", isTicker);

                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                if (isTicker) {
                    //console.log("this.tickerInput=", this.tickerInput);
                    // console.log("this.unitMove=", this.unitMove);
                    let ticker;
                    if (this.unitMove.length > 0) {
                        ticker = this.tickerLegs.map((leg, index) => leg + '*' + this.unitMove[index]).join('');
                    } else {
                        ticker = this.tickerInput;
                    }

                    generalForm.intracommodity = false;
                    // console.log("generalForm=", generalForm);

                    let tickerGeneralForm = tickerToGeneralForm(ticker, this.symbols);
                    console.log("tickerGeneralForm=", tickerGeneralForm);

                    let contractListsObject = createContractLists(tickerGeneralForm.sampleContract, tickerGeneralForm.legs);
                    console.log("contractListsObject=", contractListsObject);

                    tickerGeneralForm.browserSideOnly = {};
                    tickerGeneralForm.browserSideOnly.contractLists = contractListsObject.contractsArray;
                    tickerGeneralForm.browserSideOnly.openContractLists = contractListsObject.openContractsArray;

                    let alignerOptionsObject = ContractWeightedN_tuplets(tickerGeneralForm.sampleContract.slice(0, tickerGeneralForm.legs),
                            contractListsObject.contractsArray.slice());
                    console.log("alignerOptionsObject=", alignerOptionsObject);

                    tickerGeneralForm.browserSideOnly.alignerOptions = alignerOptionsObject.alignerOptions;
                    tickerGeneralForm.browserSideOnly.alignerOpenOptions = alignerOptionsObject.openAlignerOptions;

                    /*  commit('browserSideOnly/setContractLists', contractListsObject.contractsArray);
                     commit('browserSideOnly/setOpenContractLists', contractListsObject.openContractsArray);
                     
                     let alignerOptionsObject = ContractWeightedN_tuplets(constrainedContract.slice(0, state.legs), contractListsObject.contractsArray.slice());
                     // console.log("alignerOptionsObject=", alignerOptionsObject);
                     commit('browserSideOnly/setAlignerOptions', alignerOptionsObject.alignerOptions);
                     commit('browserSideOnly/setAlignerOpenOptions', alignerOptionsObject.openAlignerOptions);*/

                    tickerGeneralForm.instrument = "future";
                    let nearestOpen = nearestOpenSpread(tickerGeneralForm).value.replace(/\s/g, '');
                    console.log("nearestOpen=", nearestOpen);

                   
                    console.log("tickerGeneralForm.selected[0]=", tickerGeneralForm.selected[0], " nearestOpen=", nearestOpen);
                    console.log("nearestOpen === tickerGeneralForm.selected[0]=", nearestOpen === tickerGeneralForm.selected[0]);
                    this.initialState = _.merge(generalForm, tickerGeneralForm);
                    console.log("this.initialState=", JSON.parse(JSON.stringify(this.initialState)));

                    console.log("generalForm.program !== 'TradeMaps' =", generalForm.program !== "TradeMaps");
                    if (generalForm.program !== "TradeMaps" || nearestOpen === tickerGeneralForm.selected[0]) {
                        this.$store.commit(this.namespace + "/setGeneralForm", this.initialState);
                        this.$store.commit(this.namespace + "/setConstrainSampleContract", false);
                        this.$store.dispatch(this.namespace + "/getContractListsFromServer");
                    } else if (generalForm.program === "TradeMaps") {
                        tickerGeneralForm.selected[0] = nearestOpen;
                        let invertDatesMultiplier = 1;
                        let noLegSwapping = true;
                        let includeUnitMoves = true;
                        this.nearestOpenTicker = spreadTitle(JSON.parse(JSON.stringify(tickerGeneralForm)),
                            invertDatesMultiplier, noLegSwapping, this.symbols, includeUnitMoves);

                        this.$refs['trade-map-output-modal'].show();
                    }
                } else {
                    this.$refs['futures-auto-complete-modal'].show();
                }
            }
        }
    }

</script>