<template>
    <div style="border-radius: 3px; border: 1px solid gray;  margin: 0px;
                 padding: 0px; width: fit-content; border-spacing: 0;">
    <table>
        <tr>
        <td v-if="tickerInputType === 'text'" style="">
        <commodity-box-text v-bind:namespace="namespace"></commodity-box-text>
        </td>
        <td v-if="tickerInputType === 'form'">
        <commodity-box-form v-bind:namespace="namespace">
            <template v-slot:contract-aligner>
                <slot name="contract-aligner"></slot>
            </template>
        </commodity-box-form>
        </td>
        </tr>
    </table>
    </div>
</template>

<style>
table.rounded-corners {
 border-spacing: 0;
 border-collapse: separate;
 border-radius: 10px;
 border: 1px solid black;
}
</style>

<script>

    import * as fb from '@/firebase';
    import { getUnitMove } from '@/js/main';
    import commodityBoxText from '@/components/commodity-box-text.vue';
    import commodityBoxForm from '@/components/commodity-box-form.vue';

    export default {
        components: {
            commodityBoxText, commodityBoxForm
        },
        mounted() {
            // console.log("commodity-box.vue mounted() starting.");
            // console.log("this.namespace=", this.namespace);
        },
        props: {
            namespace: {
                type: String,
                default: ""
            }
        },
        data: function () {
            return {
                symbolOptions: [
                    {value: "new", text: "new"},
                    {value: "old", text: "old"},
                    {value: "ib", text: "IB"}
                ],
                options: [
                    {text: 'custom', value: 'custom'},
                    {text: 'standard', value: 'standard'}
                ]
            };
        },
        computed: {
            user() {
                return this.$store.state.user;
            },
            program() {
                return this.$store.state[this.namespace].program;
            },
            email() {
                return this.user.email;
            },
            tickerInputType: {
                get() {
                    return this.user.tickerInputType;
                },
                set() {}
            },
            seasonalSelectionMode: {
                get() {
                    return this.$store.state[this.namespace].seasonalSelectionMode;
                },
                set(seasonalSelectionMode) {
                    console.log("selected seasonalSelectionMode() starting. seasonalSelectionMode=", seasonalSelectionMode + " this.namespace=", this.namespace);
                    this.$store.commit(this.namespace + '/setSeasonalSelectionMode', seasonalSelectionMode);
                }
            }
        },
        watch: {
            tickerInputType(tickerInputType) {
                console.log("watching tickerInputType=", tickerInputType);
                this.updateTickerInputTypeToFirebase(tickerInputType);
            }
        },
        methods: {
            restoreStandardUnitMoves() {
                console.log("restoreStandardUnitMoves() starting.");
                let unitMove = this.c.map(commodity => getUnitMove(commodity));
                console.log("unitMove=", unitMove);
                this.$store.commit(this.namespace + '/setUnitMove', unitMove);

            },
            updateTickerInputTypeToFirebase(tickerInputType) {
                console.log("updateTickerInputTypeToFirebase() starting. tickerInputType=", tickerInputType);
                this.tickerInputType = tickerInputType;
                fb.usersCollection
                        .doc(this.email)
                        .update({"tickerInputType": tickerInputType})
                        .then(() => {
                            console.log('tickerInputType updated.');
                        }).catch((error) => {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                });
            }
        }
    }
</script>